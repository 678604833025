@import './../../../styles/config.scss';

.container {
    background-color: $themeLight;
    min-width: 100vw;
    min-height: 100vh;

    .initForm,
    .initLoading {
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}

.event {
    width: 100vw;
    max-width: 1440px;
    margin: 0px auto;
    padding: 20px;

    .transaction {
        padding: 20px 0px;

        &:not(:last-child) {
            border-bottom: 1px solid $neutralLight;
        }
    }
}

.card {
    .title {
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 20px;
    }

    .content {
        padding: 20px;
        background-color: $white;
        border-radius: $base-border-radius;
        box-shadow: $box-shadow;
    }
}