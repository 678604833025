@import '../../../../../styles/config.scss';

.imgContainer {
  height: 102px;
  width: 102px;
  position: relative;
  &:hover {
    .icnBtn {
      display: unset;
    }
  }
}
.icnBtn {
  position: absolute;
  background-color: $neutralLight;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
  display: none;
}

.fileImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item { 
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $black;
  .image{
    height: 30px;
    width: 30px;
    border-radius: 5px;
  }
}

.columnItem {
  margin: 5px;
}