@import './../../styles/config.scss';

.tag {
    padding: 1px 5px;
    border-radius: 4px;

    &.success {
        background-color: $status-success;
    }

    &.error {
        background-color: $status-error;
    }

    &.warning {
        background-color: $status-warning;
    }

    * {
        color: $white;
    }
}