@import '../../../styles/config.scss';

.detailsCard {
  background-color: $white;
  border-radius: $base-border-radius;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;

  .footer {
    padding-left: 20px;
    border-left: 1px solid $neutralLight;
  }
}
