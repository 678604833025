@import "../../styles/config.scss";

.menubar {
  position: fixed;
  top: 0px;
  background-color: $secondaryPrimary;
  height: 80px;
  width: 100vw;
  z-index: 999;

  .userIcon{
    padding: 20px;
  }

  .logo {
    padding: 20px;
    width: 160px;

    img {
      object-fit: contain;
    }
  }

  .menu {
    .item,
    .button {
      color: $themePrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      text-transform: uppercase;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif !important;
      font-weight: 400;

      &:hover {
        background-color: $neutralLight;
      }

      & * {
        color: $themePrimary;
      }
    }

    .button {
      height: 100%;
    }
  }
}

.subitem {
  color: $black;
  display: block;
  padding: 10px;

  &:hover {
    color: $themeDarkAlt;
    background-color: $neutralLighter;
  }
}
