@import './../../../../../styles/config.scss';

.productCard {
    padding: 10px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .productImage {
        border-radius: $border-radius;
        width: 100px !important;
        height: 100px !important;
        object-fit: cover;
        min-width: 100px;
        max-width: 100px;
    }

    .flavourContainer {
        min-width: 200px;
        max-width: 200px;
    }

    .deliveryDetailsContainer {
        border-left: 1px solid #ddd;
        padding-left: 20px;
    }
}