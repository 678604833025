@import './../../styles/config.scss';

.breadCrumbItem {
    padding: 5px 10px;
    color: $black;

    &.currentItem {
        font-weight: 600;
    }

    &:hover, &:focus {
        color: $themePrimary;
    }
}